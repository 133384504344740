import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LogoImg from '../images/spider.png';
import { auth } from '../firebase'; // Adjust this path to where your firebase.js is located
import { onAuthStateChanged, signInWithRedirect, GoogleAuthProvider, signOut } from 'firebase/auth';

const Header = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <Wrapper>
      <Links>
        <Link href="#abelicious">Abelicious</Link>
        <Link href="#music">Music</Link>
        <Link href="#home">Contact</Link>
      </Links>
      <Container>
        {user ? (
          <>
            <UserInfo>
              <Avatar src={user.photoURL} alt="User Avatar" />
            </UserInfo>
            <Logout onClick={handleLogout}>Logout</Logout>
          </>
        ) : (
          <Login onClick={handleSignIn}>Login</Login>
        )}
        <Logo src={LogoImg} />
      </Container>
    </Wrapper>
  );
};

export default Header;





const Wrapper = styled.div`

    width: 100%;
    height: 70px;

    position: sticky;
  top: 0;
  left: 0;
  right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: white;
    background-color: #4c0065;

    overflow: hidden;
    

`


const Links = styled.div` 

    
  float: left;
  display: block; 
  //display: flex;
  justify-content: space-around;
  align-items: center;
  color: #f2f2f2;
  text-align: center;
  //padding: 14px;
  text-decoration: none;
   //margin-right: 30px;
   text-shadow: 2px 2px 8px #000000;

`
const Link = styled.a`


height: 70px;
padding:8px;
 text-decoration: none;
 color: white;
 font-size: 45px;
 text-shadow: 2px 2px 8px #000000;


 &:hover {
    
  background-color: purple;
    color: #111;
    text-decoration: none;
    outline: none;
    text-shadow: 2px 2px 8px rgba(90,0,157,1);
    -webkit-box-shadow: 1px 4px 13px 1px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 4px 13px 1px rgba(0,0,0,0.75);
box-shadow: 1px 4px 13px 1px rgba(0,0,0,0.75);
    
 }

 @media (max-width: 1280px) {
   
   font-size: 40px;


}


 @media (max-width: 800px) {
   
   font-size: 40px;


}


 @media (max-width: 800px) {
   
   font-size: 12px;


}

 @media (max-width: 768px) {
   
    font-size: 12px;


}


`

const Container = styled.div`

 display: flex;
 justify-content: center;
 align-items: center;
 

`

const Login = styled.button` 

width: 90px;
height: 40px;
//margin-top: 10px;
text-align: center;
border: none;
background-color: red;
border-color: black;
border-width: 2px;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;


  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    margin-bottom: 20px;
  }



`



const Logo = styled.img`
    width: 70px;
    height: 50px;
    margin: 15px;
    margin-left: 30px;

    &:hover {

      cursor: pointer;
      transform: scale(1.2);

    }


`

const Avatar = styled.img`
  width: 35px;  // Smaller width for mobile
  height: 35px; // Smaller height for mobile
  border-radius: 50%;
  margin: 10px 15px; // Adjusted margin for mobile

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  @media (min-width: 768px) {
    width: 50px;  // Original width for desktop
    height: 50px; // Original height for desktop
    margin: 15px;
    margin-left: 30px; // Original margin-left for desktop
  }
`;


const UserInfo = styled.div`
  /* Your styling */
`



const Logout = styled.button`
  /* Your styling */
`;

