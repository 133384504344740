import React from 'react';
import styled from 'styled-components';
import YoutubeVideo from './YoutubeVideo';

const Video = () => {
  return (
    <Wrapper>
      <YoutubeVideo />
    </Wrapper>
  );
};

export default Video;

const Wrapper = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 20px;
  }
`;
