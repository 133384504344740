import React from 'react'
import styled from 'styled-components'
import SpotifyPlayer from 'react-spotify-player';
import Dethklok from '../images/dethklok.gif'
import Youtube from './YoutubeVideo'



const Media = () => {

    const size = {
        width: '400vh',
        height: '100%',
      };


  return (
   
   <Wrapper>


        <SpotifyWrapper>
            <SpotifyPlayer 
                uri='https://open.spotify.com/playlist/6i1Basy0fXRW4HytYXHNF3?si=0a2101f1daa24e51'
                size={size}
                theme='black'
                view='list'
            
            /> 
        </SpotifyWrapper>

       


        <DethklokGif src={Dethklok} alt=''/>

        
    </Wrapper>    
    
    )
}


export default Media


const Wrapper = styled.div`


height: 100vh;
width: 100%;
display: flex;
margin-top: 40px;
flex-direction: column;
align-items: center;
justify-content: center;
`

const DethklokGif = styled.img`

margin: 40px;
`
const SpotifyWrapper = styled.div`


-webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
  -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
  box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
   margin-bottom: 30px;
  height: 700px;



`


const YoutubeVideo = styled.div`

    margin-top: 40px;
`


