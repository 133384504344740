import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import SplashImage from '../images/IMG_4109.jpg'; // Ensure the path is correct

const SplashScreen = ({ onEnd }) => {
  useEffect(() => {
    const timer = setTimeout(onEnd, 5000); // Show splash screen for 5 seconds
    return () => clearTimeout(timer);
  }, [onEnd]);

  return (
    <Wrapper>
      <Text>🕸️Welcome to my Web🕸️</Text>
      <Image src={SplashImage} alt="Splash" />
    </Wrapper>
  );
};

export default SplashScreen;

const popEffect = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.25);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: black;
  position: relative;
`;

const Text = styled.h1`
  color: #4C0065;
  animation: ${popEffect} 1.5s ease-in-out;
  text-align: center;
  text-shadow: 0 0 5px white, 0 0 10px white, 0 0 15px white, 0 0 20px white;
  font-size: 5rem;
  position: absolute;
  top: 20%;
  z-index: 2;

  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 70%; 
  object-fit: contain;
  z-index: 1;
`;
