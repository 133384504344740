import './App.css';
import styled from 'styled-components';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';

function App() {
  return (
    <Wrapper>

      <Header />
      <Main />
      <Footer />
    </Wrapper>

     



  );
}

export default App

const Wrapper = styled.div`

    font-family: "Creepster"
`

