import React from 'react'
import styled from 'styled-components'
import ProfilePic from '../images/Abel ProfilePic.jpg'
import Onlyfans from '../images/onlyfansimg.png'
import TikTokLogo from '../images/tiktokicon.png'
import WishList from "../images/Amazon-wishlist-icon-2.png"



const About = () => {
  return (
   <Wrapper>
      <Name>☠️Abel Mendoza☠️</Name>
      <Picture src= {ProfilePic}></Picture>
      <Bio>Massive Legend</Bio>
      <h1>Get to know Me</h1>
      <Socials>
            <a href="https://twitter.com/A_BrownRecluse" target="_blank"><i class='fab fa-twitter'></i></a>
            <a href="https://www.instagram.com/abel_elreaper/" target="_blank"><i class="fab fa-instagram"></i></a>
            <a href="https://www.tiktok.com/@alereaperhomebrewing" target="_blank"><i class="fab fa-tiktok"></i></a>
            <a href="https://onlyfans.com/neckromancer666">
                <img alt="OF" src={Onlyfans}
                width="30" height="30"></img>
            </a>


            <a href="https://www.tiktok.com/@alereaperhomebrewing">
                <img alt="tiktok" src={TikTokLogo}
                width="50" height="50"></img>
            </a>

            <a href="https://www.amazon.com/hz/wishlist/ls/2J3EK9BGFIQL4?ref_=wl_share">
                <img alt="wishlist" src={WishList}
                width="50" height="50"></img>
            </a>
      </Socials>
   </Wrapper>
  )
}

export default About


const Wrapper = styled.div`

    color: white;
    font-weight: 1200px;
    max-width: 600px;
    margin: auto;
    margin-top: -5px;
    margin-bottom: 30px;
    line-height: 2.5em;
    padding-top: 20px;
    padding-bottom: 71px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px 8px #000000;


`

const Name = styled.div`

  margin-top: 20px;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 30px;
  text-shadow: 2px 2px 8px #000000;
  cursor: pointer;
`

const Picture = styled.img`

  height: 700px;
  width: 600px;

  margin-top: 20px;
  cursor: pointer;
  border-radius: 9px 9px 9px 9px;
  -moz-border-radius: 9px 9px 9px 9px;
  -webkit-border-radius: 9px 9px 9px 9px;
  border: 0px solid #000000;
  -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
  &:hover {
        cursor: pointer;

      -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
    }
 
    @media (max-width: 1194px) {
      height: 600px;
      width: 500px; 
      -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);


}

    @media (max-width: 1024px) {
      height: 500px;
      width: 400px; 
      -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);


}

@media (max-width: 1024px) {
      height: 500px;
      width: 400px; 
      -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);


}
 
    @media (max-width: 768px) {
    height: 400px;
  width: 300px; 
  -webkit-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      -moz-box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);
      box-shadow: 10px 10px 39px 45px rgba(0,0,0,0.87);


}





`

const Bio = styled.div`

  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 2px 2px 8px #000000;
  cursor: pointer;

`

const Socials = styled.div`

  padding: 10px;
	display: flex;
	position: center;
  align-items: space-around;
  align-content: center;
	justify-content:center;


  & > a {

    margin: 10px;
    font-size: 2rem;
    text-align:center;
    display: inline-block;
    color: white;
  }

  & > a:hover { 

    color: black;
    }


`
