import React from 'react'
import styled from 'styled-components'


const Email = () => {
  return (


    <Wrapper>

        <form>
            <label>
                Shoot Me your 📧 and lets Connect:
                <input type="text" name="name" />
            </label>
            <input type="submit" value="Submit" />
        </form>
    </Wrapper>
     
  )
}

export default Email



const Wrapper = styled.div`

    margin: 30px;
    margin-top:80px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-shadow: 2px 2px 8px #000000;


    & > label {
    
        font-size: 40px;
  }

    
`
