import React, { useState } from 'react';
import styled from 'styled-components';
import GracielaGif from '../images/graciela-tits.gif';

const AgeVerification = ({ onVerified }) => {
  const [birthday, setBirthday] = useState('');
  const [error, setError] = useState('');

  const handleVerify = () => {
    const age = getAge(new Date(birthday));
    if (age >= 18) {
      onVerified();
    } else {
      setError('You must be over 21 to enter this site.');

    }
  };

  const getAge = (birthDate) => {
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  return (
    <Wrapper>
      <GifContainer>
        <Gif src={GracielaGif} alt="Mature Content GIF" />
      </GifContainer>
      <Notice>Mature Content Notice: You must be over 21 to enter this site.</Notice>
      <Input
        type="date"
        value={birthday}
        onChange={(e) => setBirthday(e.target.value)}
        placeholder="Enter your birthday"
      />
      <Button onClick={handleVerify}>Verify Age</Button>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default AgeVerification;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: black;
  color: white;
`;

const GifContainer = styled.div`
  margin-bottom: 20px;
`;

const Gif = styled.img`
  max-width: 100%;
  height: auto;
`;

const Notice = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4C0065;
  color: black;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: purple;
  }
`;

const Error = styled.p`
  color: red;
  margin-top: 10px;
`;
