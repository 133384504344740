
import React from 'react'
import styled from 'styled-components'


const Footer = () => {
  return (
   <Wrapper>
        

        <Copyright>© 2022   
            <Link href="#abelmendoza" target="_blank" color='white'> Abel Mendoza</Link>
        </Copyright>
       
   </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`

    width: 100%;
    height: 50px;
    background-color: #4c0065;
    font-size: 30px;
    color: white;
    

`


const Copyright = styled.div`

     display: flex;
    justify-content: center;
    align-items: center;
    
`

const Link = styled.a`
    
    text-decoration: none;
    color: white;
    margin-left: 5px;

    &:hover {
    
      color: black;
    }


`
