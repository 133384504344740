import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import About from './About';
import Media from './Media';
import Video from './Video';
import Email from './Email';
import SplashScreen from './SplashScreen';
import AgeVerification from './AgeVerification';

import BackGroundImg from '../images/purple-web.png';

const Main = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const sessionData = JSON.parse(localStorage.getItem('sessionData'));
    if (sessionData) {
      const { verified, timestamp } = sessionData;
      const currentTime = Date.now();
      if (currentTime - timestamp < 7 * 60 * 1000) { // 7 minutes
        setVerified(verified);
      } else {
        localStorage.removeItem('sessionData');
      }
    }
  }, []);

  const handleVerification = () => {
    const sessionData = {
      verified: true,
      timestamp: Date.now()
    };
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
    setVerified(true);
  };

  const handleSplashEnd = () => {
    setShowSplash(false);
  };

  useEffect(() => {
    if (!showSplash) {
      const timer = setTimeout(() => setShowSplash(true), 7 * 60 * 1000); // 7 minutes
      return () => clearTimeout(timer);
    }
  }, [showSplash]);

  if (showSplash) {
    return <SplashScreen onEnd={handleSplashEnd} />;
  }

  if (!verified) {
    return <AgeVerification onVerified={handleVerification} />;
  }

  return (
    <Wrapper>
      <About />
      <Media />
      <Video /> 
      <Email />
    </Wrapper>
  );
};

export default Main;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${BackGroundImg});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
