import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

class YoutubeVideo extends React.Component {
  render() {
    const opts = {
      height: '500', // Increased height for better viewing
      width: '900', // Increased width for better viewing
      playerVars: {
        autoplay: 0,
        start: 2,
      },
    };

    return (
      <VideoWrapper>
        <YouTube videoId="ZXNYI7NTOcE" opts={opts} onReady={this.onReady} />
      </VideoWrapper>
    );
  }

  onReady(event) {
    // Access to player in all event handlers via event.target
    // event.target.pauseVideo(); // Uncomment if you need to pause the video initially
  }
}

export default YoutubeVideo;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 500px; // Maximum width for larger screens
  height: auto;

  @media (max-width: 768px) {
    max-width: 640px; // Adjust for tablets
  }

  @media (max-width: 480px) {
    max-width: 320px; // Adjust for mobile devices
  }

  iframe {
    width: 100%;
    height: auto;
  }
`;
